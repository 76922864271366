import React from 'react'
import Layout from 'components/gatsby/layout'
import SEO from 'components/gatsby/seo'
import { Link } from 'gatsby'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { Typography, Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useComponentTranslation from '../hooks/useComponentTranslation'

const insideBrowser = typeof window !== 'undefined'

const IndexPage = () => {
    const { i18n } = useTranslation()
    const { t } = useComponentTranslation('pages.index')

    function setLanguage(lang) {
        i18n.changeLanguage(lang)
        if (insideBrowser) {
            localStorage.setItem('lang', lang)
        }
    }

    return (
        <Layout>
            <SEO title={t('title')} description={t('description')} />
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid container justify="center" style={{ marginBottom: 20 }}>
                    <Grid
                        item
                        onClick={() => setLanguage('de')}
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                        <Typography variant="h5" component="h1">
                            {t('german')}
                        </Typography>
                        <img
                            style={{ width: 50 }}
                            alt="german icon"
                            src="/de-flag.png"
                        />
                    </Grid>
                    <Grid
                        item
                        onClick={() => setLanguage('en')}
                        style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginLeft: 20,
                        }}
                    >
                        <Typography variant="h5" component="h1">
                            {t('english')}
                        </Typography>
                        <img
                            style={{ width: 50 }}
                            alt="english icon"
                            src="/en-flag.png"
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" component="h1">
                    {t('welcome')}
                </Typography>
                <Typography variant="h6" component="h2" paragraph>
                    {t('welcomeSub')}{' '}
                </Typography>
                <Typography
                    variant="body1"
                    style={{
                        width: '60%',
                        textAlign: 'center',
                        marginBottom: 10,
                    }}
                >
                    {t('ownText')}
                </Typography>
                <Link to="/roles" style={{ textDecoration: 'underline' }}>
                    {t('roles')}
                </Link>
                <Link
                    to="/stages"
                    style={{ textDecoration: 'underline', marginTop: 10 }}
                >
                    {t('stages')}
                </Link>
                <a
                    href="https://discord.com/invite/xkrWYUMkXE"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        background: 'black',
                        color: 'white',
                        padding: 10,
                        marginTop: 20,
                    }}
                >
                    {t('haveFeedback')}
                </a>
                <img
                    alt="werewolf icon"
                    style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}
                    src="/werewolf-icon.png"
                />
                <Grid container justify="center">
                    <Grid item style={{ marginRight: 10 }}>
                        <PhoneAndroidIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{t('noSetup')}</Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item style={{ marginRight: 10 }}>
                        <GroupAddIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{t('numberPlayers')}</Typography>
                    </Grid>
                </Grid>
                <Button
                    style={{ marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    data-v="19.35"
                >
                    <Link to="/game">{t('play')}</Link>
                </Button>
                {/* <Typography variant="body1" component="h1">
                    draft: this online version of werewolf tries to solve issues
                    that other werewolf versions may have. We strongly believe
                    in UX and therefore gave our best to tackle this. Unlike
                    other games we want to offer great experience during play -
                    thats why we dont support playing games with random players
                    since its not uncommon that you would play with people who
                    are not committed to the game or dont understand their role.
                    we embrace playing locally or online with existing friends
                    or using our discord to make new friends to play with them
                    and hope this is a better way to bring overall more quality
                    and fun into the game. You can play this version also
                    locally on your phone with your friends and dont even need a
                    narrator
                </Typography> */}
            </Grid>
        </Layout>
    )
}

export default IndexPage
